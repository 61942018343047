.preloadWrapper{
    height: 100vh;
    width: 100vw;
    background-color: #1e212a;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    position: relative;
    display: flex;
}

.video {
    width: 256px;
    height: 256px;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
}